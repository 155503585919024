<template>
  <div>
    <page-title :heading="$t('erp.lang_specialOffers')" show-previous-button :subheading="$t('erp.lang_editSpecialOffer')" :icon=icon></page-title>
    <div class="app-main__inner">
      <EditSpecialOfferComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import EditSpecialOfferComponent from "../../../../components/erp/baseData/specialoffers/EditSpecialOfferComponent.vue";

export default {
  components: {
    PageTitle,
    EditSpecialOfferComponent
  },

  data: () => ({
    icon: 'pe-7s-plugin icon-gradient bg-tempting-azure',
  })
}
</script>